import { useRef, useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { usePrompt } from './shared/hooks'
// import axios from 'axios'
// import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DragResizeContainer from '../lib/drag-resize'
import { AiFillCaretLeft, AiFillCaretRight, AiFillCaretUp, AiFillCaretDown, AiFillPlusCircle, AiFillDelete, AiOutlineCheck, AiOutlineCaretRight, AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";

import { Wrapper, Header, Inner, Workspace, Arrow, Hole, Col, Row, Summary, Modal } from './shared/styled'
import { useLanguage } from './shared/hooks';
//import ReactPDF, {PDFDownloadLink, Document, Page, View, StyleSheet } from '@react-pdf/renderer';

import logo from '../konsport-logo.png'


const HoleDesign = () => {
    const workspace = useRef()
    const {width, height} = useParams()
    const [workspaceHeight, setWorkspaceHeight] = useState(480)
    const [holes, setHoles] = useState([])
    const [active, setActive] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [blocker, setBlocker] = useState(false)
    const { print } = useLanguage()
    usePrompt("Twój projekt jest niezapisany, na pewno chcesz kontynuować?", blocker);
    // const [email, setEmail] = useState('')
    // const [name, setName] = useState('')
    // const [comment, setComment] = useState('')
    const canResizable = (isResize) => {
        return { top: isResize, right: isResize, bottom: isResize, left: isResize, topRight: isResize, bottomRight: isResize, bottomLeft: isResize, topLeft: isResize };
    };
    
    const grid = 1
    useEffect(() => {
        const workspaceRatio = height/width
        setWorkspaceHeight(workspace.current.clientWidth * workspaceRatio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace])
    
    const round = (n) => {
        const result = Math.round(n/grid)*grid
        if(result < 0) {
            return 0
        }
        return result
    }     
    const onLayoutChange = (e) => {
        setHoles(e)
    }
    const roundItem = (item) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight
        const ratioWidth = width / (workspaceWidth)
        const ratioHeight = height / workspaceHeight
        return {
            ...item, 
            x: round(calculateMM(item, 'left')) / ratioWidth, 
            y: round(calculateMM(item, 'top')) / ratioHeight,
            width: round(calculateMM(item, 'width')) / ratioWidth,
            height: round(calculateMM(item, 'height')) / ratioHeight,
        }
    }
    const addItem = () => {
        const key = Math.floor(Math.random()*999999)
        setActive(key)
        setBlocker(true)
        setHoles([...holes, {key, width: 150, height: 150, x: 50, y: 50, zIndex: holes.length+1}])
    }
    const deleteItem = (item) => {
        setHoles([...holes.filter(k => k.key !== item.key)])
    }
    const calculateMM = (item, area) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight

        const ratioWidth = width / workspaceWidth
        const ratioHeight = height / workspaceHeight
        switch(area) {
            case 'left': {
                return Math.round(item.x * ratioWidth)
            }
            case 'right': {
                return Math.round(width - (item.x + item.width) * ratioWidth)
            }
            case 'top': {
                return Math.round(item.y * ratioHeight)
            }
            case 'bottom': {
                return Math.round(height - (item.y + item.height) * ratioHeight)
            }
            case 'width': {
                return Math.round(item.width * ratioWidth)
            }
            case 'height': {
                return Math.round(item.height * ratioHeight)
            }
            default:
                return 0
        }
    }
    const calculateDistance = (item, area) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight

        switch(area) {
            case 'left': {
                return Math.round(item.x)
            }
            case 'right': {
                return Math.round(workspaceWidth - (item.x + (item.width)))
            }
            case 'top': {
                return Math.round(item.y)
            }
            case 'bottom': {
                return Math.round(workspaceHeight - (item.y + item.height))
            }
            case 'width': {
                return Math.round(item.width)
            }
            case 'height': {
                return Math.round(item.height)
            }
            default:
                return 0
        }
    }
    const generatePdf = async () => {
        setBlocker(false)
        setShowModal(false)
        html2canvas(document.querySelector("#capture"), {scrollY: -window.scrollY,
            removeContainer: false, onclone: function (documentCloned) {
                const holesItem = documentCloned.querySelectorAll('.child-container');
                for (const hole of holesItem) {
                    hole.classList.add('active');
                }
            }}).then(canvas => {
                const canvasWidth = canvas.width
                const canvasHeight = canvas.height
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: canvasWidth > canvasHeight ? "landscape" : "portrait",
                });
                var w = pdf.internal.pageSize.getWidth();
                var h = pdf.internal.pageSize.getHeight();

                pdf.addImage(imgData, 'PNG', 0, 0, Math.min(w, canvasWidth), Math.min(h, canvasHeight));
                pdf.save("download.pdf"); 
        });
    }
    // const sendProject = async () => {
    //     const id = uuidv4()
    //     const payload = {
    //         id,
    //         email: 'shatens@gmail.com',
    //         name: 'Project',
    //         pdfUrl: 'https://google.com',
    //         project: {
    //             width,
    //             height,
    //             holes
    //         }
    //     }
    //     const data = await axios.post('https://www.api.my.konsport.com/wyciecia/projects', payload, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     console.log('response', data)
    // }

    // eslint-disable-next-line no-unused-vars
    const calculateSize = (value, area) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight

        const ratioWidth = width / workspaceWidth
        const ratioHeight = height / workspaceHeight

        switch(area) {
            case 'x': {
                return value / ratioWidth
            }
            case 'y': {
                return value / ratioHeight
            }
            case 'width': {
                return value / ratioWidth
            }
            case 'height': {
                return value / ratioHeight
            }
            default:
                return 0
        }
    }
    const ReactDOMRender = (
        <Wrapper>
            {showModal ? <Modal data-html2canvas-ignore>
                <div className="modal-inner">
                    <div className="content">
                        <AiOutlineClose style={{position: 'absolute', top: 10, right: 10, fontSize: 20, cursor: 'pointer'}} onClick={() => setShowModal(false)} />
                        <Row style={{fontSize: '20px', textAlign: 'center'}}>
                            {print('finish_project')}
                        </Row>
                        <Row style={{flexDirection: 'row', display: 'flex', marginTop: 40}}>
                            <input type="email" placeholder='Adres email'></input>
                            <input type="text" placeholder='Nazwa projektu'></input>
                            <input type="text" placeholder='Komentarz'></input>
                        </Row>
                        <Row style={{width: 300, alignSelf: 'center', marginTop: 60}}>
                            <button className="preview" onClick={() => {
                                generatePdf()
                            }}>
                                <span>{print('download_preview')}</span>
                            </button>
                            {/* <button className="send">
                                <span>Wyślij projekt</span>
                            </button> */}
                        </Row>
                    </div>
                </div>
            </Modal> : <></>}
            <Row style={{alignSelf: 'flex-start'}} className="btn-end" data-html2canvas-ignore>
                {holes.length > 0 ?<button onClick={() => generatePdf()} style={{backgroundColor: '#38A78B'}}><AiOutlineCheck /> <span>{print('finish_design')}</span></button> : <></>}
            </Row>
            <Header>
                <Col>
                    <Row>
                        <span>{print('span_width')}: &nbsp;<strong>{width}mm</strong></span>
                    </Row>
                    <Row>
                        <span>{print('span_height')}: &nbsp;<strong>{height}mm</strong></span>
                    </Row>
                </Col>
                <Col>
                <Row style={{alignSelf: 'flex-end'}} data-html2canvas-ignore>
                        <button onClick={() => addItem()}><AiFillPlusCircle /> <span>{print('technical_hole')}</span></button>
                    </Row>
                </Col>
                <Col>
                    <Row style={{alignSelf: 'flex-end'}} data-html2canvas-ignore>
                        <button onClick={() => document.location.href = '/'} style={{backgroundColor: '#444'}}><AiFillCloseCircle /> <span>{print('start_fresh')}</span></button>
                    </Row>
                </Col>
            </Header>
            <Inner style={{position: 'relative'}} height={workspaceHeight}>
                <Workspace ref={workspace}>
                    <div className="bg"></div>
                    <DragResizeContainer 
                        className='resize-container'
                        resizeProps={{ 
                            minWidth: 100, 
                            minHeight: 50, 
                            enable: canResizable(true),
                            handle: '.handle',
                            onResizeStop: () => setTimeout(() => setHoles(holes.map(item => roundItem(item))), 0)
                        }}
                        layout={holes}
                        dragProps={{ disabled: false, onStop: () => setTimeout(() => setHoles(holes.map(item => roundItem(item))), 0) }}
                        onLayoutChange={onLayoutChange}
                    >
                        {holes.map((single, i) => {
                            return (
                                <Hole key={single.key} className={`child-container size-auto border ${active === single.key ? ' active' : ''}`} onClick={() => {
                                    setActive(single.key)
                                }}>
                                    <div className='number'>{i+1}</div>
                                    <div className="inputs">
                                        <div>
                                            <span>{print('width')}</span>
                                            <input type="number" value={`${calculateMM(single, 'width')}`} onChange={(e) => {
                                                setHoles(holes.map((hole) => {
                                                    if(hole.key === single.key) {
                                                        hole.width = calculateSize(e.target.value, 'width')
                                                        return hole
                                                    }
                                                    return hole
                                                }))
                                            }}/>mm
                                        </div>
                                        <div>
                                            <span>{print('height')}</span>
                                            <input type="number"  value={`${calculateMM(single, 'height')}`} onChange={(e) => {
                                                setHoles(holes.map((hole) => {
                                                    if(hole.key === single.key) {
                                                        const value = parseInt(e.target.value)
                                                        if(!isNaN(value)){
                                                            hole.height = calculateSize(e.target.value, 'height')
                                                            return hole
                                                        }
                                                    }
                                                    return hole
                                                }))
                                            }} />mm
                                        </div>
                                        <div className="footer">
                                            <AiFillDelete onClick={() => setTimeout(() => deleteItem(single), 0)} style={{fontSize: '20px', marginLeft: '5px', cursor: 'pointer', zIndex: 5}}></AiFillDelete>
                                            <AiOutlineCaretRight className={'handle'} style={{position: 'absolute', right: -7, bottom: -10, fontSize: 20, transform: 'rotate(45deg)'}} />
                                        </div>
                                    </div>
                                    <div className="arrows">
                                        <Arrow key={'left-arrow'} className="left arrow" index={i} size={calculateDistance(single, 'left')} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.x = calculateSize((calculateMM(single, 'left') - grid), 'x')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretRight  data-html2canvas-ignore />
                                            <div className="info">
                                                <AiFillCaretLeft className="button-arrow" style={{float: 'left'}} data-html2canvas-ignore />
                                                <strong>{calculateMM(single, 'left')}</strong>mm
                                            </div>
                                        </Arrow>
                                        <Arrow key={'right-arrow'} className="right arrow" size={calculateDistance(single, 'right')} index={i} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.x = calculateSize((calculateMM(single, 'left') + grid), 'x')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretLeft  data-html2canvas-ignore />
                                            <div className="info">
                                                <strong>{calculateMM(single, 'right')}</strong>mm
                                                <AiFillCaretRight className="button-arrow" data-html2canvas-ignore />
                                            </div>
                                        </Arrow>
                                        <Arrow key={'top-arrow'} className="top arrow" size={calculateDistance(single, 'top')} index={i} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.y = calculateSize((calculateMM(single, 'top') - grid), 'y')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretDown  data-html2canvas-ignore />
                                            <div className="info">
                                                <strong>{calculateMM(single, 'top')}</strong>mm
                                                <AiFillCaretUp className="button-arrow" data-html2canvas-ignore />
                                            </div>
                                        </Arrow>
                                        <Arrow key={'bottom-arrow'} className="bottom arrow" size={calculateDistance(single, 'bottom')} index={i} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.y = calculateSize((calculateMM(single, 'top') + grid), 'y')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretUp  data-html2canvas-ignore />
                                            <div className="info">
                                                <strong>{calculateMM(single, 'bottom')}</strong>mm
                                                <AiFillCaretDown className="button-arrow" data-html2canvas-ignore />
                                            </div>
                                        </Arrow>
                                    </div>
                                </Hole>
                            );
                        })}
                    </DragResizeContainer>
                </Workspace>
            </Inner>
            <div style={{width: '100%', height: 60, position: 'relative'}}>
                <img className="logo" style={{clear: 'both', position: 'absolute', right: 0}} width="200" src={logo} alt="logo" />
            </div>
            <Summary>
                <h2>{print('project_holes')}</h2>
                {holes.map((hole, i) => {
                    return (
                        <div className="item">
                            <span style={{width: 100}}>{print('hole')} {i+1}</span>
                            <span>{print('width')}: <strong>{calculateMM(hole, 'width')}mm</strong></span>
                            <span>{print('height')}: <strong>{calculateMM(hole, 'height')}mm</strong></span>
                            <span>{print('distance')} {print('left')}: <strong>{calculateMM(hole, 'left')}mm</strong></span>
                            <span>{print('distance')} {print('right')}: <strong>{calculateMM(hole, 'right')}mm</strong></span>
                            <span>{print('distance')}  {print('top')}: <strong>{calculateMM(hole, 'top')}mm</strong></span>
                            <span>{print('distance')}  {print('bottom')}: <strong>{calculateMM(hole, 'bottom')}mm</strong></span>
                        </div>
                    )
                })}
            </Summary>
        </Wrapper>
    )
    return ReactDOMRender
}

export default HoleDesign