import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App.js'
import Hole from './pages/hole.js';
import HoleExtra from './pages/hole-extra.js';
import Mailbox from './pages/mailbox.js';
import HoleDesign from './pages/hole-design.js';
import MailboxDesign from './pages/mailbox-design.js';
import reportWebVitals from './reportWebVitals.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

      <BrowserRouter>
        <App>
          <Routes>
            <Route path="/" element={<Hole />} />
            <Route path="/hole" element={<Hole />} />
            <Route path="/extra" element={<HoleExtra />} />
            <Route path="/mailbox" element={<Mailbox />} />
            <Route path="/hole/design/:width/:height" element={<HoleDesign />} />
            <Route path="/mailbox/design/:width/:height" element={<MailboxDesign />} />
          </Routes>
        </App>
      </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
