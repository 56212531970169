export const language = {
    technical_hole: {
        en: 'Technical hole',
        pl: 'Wycięcie techniczne'
    },
    mailbox_span: {
        en: 'Mailbox in span',
        pl: 'Skrzynka na listy w przęśle'
    },
    marki_creator: {
        en: 'Marki creator',
        pl: 'Kreator Marki'
    },
    cut_models: {
        en: 'Cuts for standard models',
        pl: 'Wycięcia dla modeli'
    },
    cut_dimension: {
        en: 'Cuts for dimension',
        pl: 'Wycięcia dla wymiaru'
    },
    span_model: {
        en: 'Span model',
        pl: 'Model przęsła'
    },
    span_height: {
        en: 'Span height',
        pl: 'Wysokość przęsła'
    },
    span_width: {
        en: 'Span width',
        pl: 'Szerokość przęsła'
    },
    important_notice: {
        en: 'Important: entered values will be rounded to tenths of cm (f.e 1877mm into 1880mm)',
        pl: 'Ważne: wpisane wartości będą zaokrąglane do dziesiątek cm (np. 1877mm do 1880mm)'
    },
    choose_from_list: {
        en: 'Choose from the list',
        pl: 'Wybierz z listy'
    },
    start_design: {
        en: 'Start design',
        pl: 'Rozpocznij projektowanie'
    },
    value_rounded: {
        en: 'Value will be rounded to',
        pl: 'Wartość będzie zaokrąglona do'
    },
    finish_project: {
        en: `To finish your design enter your email<br />
        and name of the project.<br />
        You can see preview of your project.`,
        pl: `Aby zakończyć projektowanie, wpisz Twój adres<br />
        email i nazwę projektu i naciśnij "Wyślij projekt".<br />
        Możesz wcześniej sprawdzić podgląd projektu.`
    },
    download_preview: {
        en: 'Download preview',
        pl: 'Pobierz podgląd'
    },
    start_fresh: {
        en: 'Start fresh',
        pl: 'Zacznij od nowa'
    },
    finish_design: {
        en: 'Finish design',
        pl: 'Zakończ projektowanie'
    },
    width: {
        en: 'Width',
        pl: 'Szerokość'
    },
    height: {
        en: 'Height',
        pl: 'Wysokość'
    },
    distance: {
        en: 'Distance from',
        pl: 'Odległość od'
    },
    left: {
        en: 'left',
        pl: 'lewej'
    }
    ,
    right: {
        en: 'right',
        pl: 'prawej'
    }
    ,
    top: {
        en: 'top',
        pl: 'góry'
    }
    ,
    bottom: {
        en: 'bottom',
        pl: 'dołu'
    },
    project_holes: {
        en: 'Konsport project - technical holes',
        pl: 'Projekt Konsport - wycięcia'
    },
    project_mailbox: {
        en: 'Konsport project - mailbox',
        pl: 'Projekt Konsport - skrzynka na listy'
    },
    hole: {
        en: 'Hole',
        pl: 'Wycięcie'
    },
    mailbox_position: {
        en: 'Mailbox position',
        pl: 'Pozycja skrzynki na listy'
    },
    your_span_width: {
        en: 'Your span width',
        pl: 'Szerokość Twojego przesła'
    },
    your_span_height: {
        en: 'Your span height',
        pl: 'Wysokść Twojego przesła'
    },
    mailbox_size: {
        en: 'Mailbox dimensions',
        pl: 'Wymiary skrzynki'
    },
    short_width: {
        en: 'W',
        pl: 'Szer'
    },
    short_height: {
        en: 'H',
        pl: 'Wys'
    }
}