export const types = [
    {
        "name": "P64",
        "sizes": [
            0,
            360,
            460,
            560,
            660,
            760,
            860,
            960,
            1060,
            1160,
            1260,
            1360,
            1460,
            1560,
            1660,
            1760,
            1860,
            1960,
            2060,
        ]
    },
    {
        "name": "PP002P",
        "sizes": [
            0,
            350,
            435,
            520,
            605,
            690,
            775,
            860,
            945,
            1030,
            1115,
            1200,
            1285,
            1370,
            1455,
            1540,
            1625,
            1710,
            1795,
            1880,
            1965,
            2050
        ]
    },
    {
        "name": "P82",
        "sizes": [
            0,
            270,
            365,
            460,
            555,
            650,
            745,
            840,
            935,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "PINO1",
        "sizes": [
            0,
            650,
            745,
            840,
            935,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "PINO2",
        "sizes": [
            0,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "NOCE1",
        "sizes": [
            0,
            650,
            745,
            840,
            935,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "PINO2",
        "sizes": [
            0,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "COLORE1",
        "sizes": [
            0,
            650,
            745,
            840,
            935,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "COLORE2",
        "sizes": [
            0,
            1030,
            1125,
            1220,
            1315,
            1410,
            1505,
            1600,
            1695,
            1790,
            1885,
            1980,
            2075,
        ]
    },
    {
        "name": "MIX",
        "sizes": [
            0,
            615,
            710,
            805,
            900,
            995,
            1090,
            1185,
            1280,
            1375,
            1470,
            1565,
            1660,
            1755,
            1850,
            1945,
            2040,
            2135,
            2230,
        ]
    },
    {
        "name": "P102",
        "sizes": [
            0,
            330,
            445,
            560,
            675,
            790,
            905,
            1020,
            1135,
            1250,
            1365,
            1480,
            1595,
            1710,
            1825,
            1940,
            2055,
            2170,
        ]
    },
    {
        "name": "PS004",
        "sizes": [
            0,
            177,
            252,
            327,
            402,
            477,
            552,
            627,
            702,
            777,
            852,
            927,
            1002,
            1077,
            1152,
            1227,
            1302,
            1377,
            1452,
            1527,
            1602,
            1677,
            1752,
            1827,
            1902,
            1977,
            2052,
            2127,
            2202
        ]
    },
]