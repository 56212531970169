import { useState } from 'react'
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import bg from '../assets/bg.png';
import { useLanguage } from './shared/hooks';

const Mailbox = () => {
    const [width, setWidth] = useState(null)
    const [height, setHeight] = useState(null)

    const round = (n) => Math.round(n/10)*10
    const { print } = useLanguage()
    return (
        <Wrapper>
            <Inner>
                <Col>
                    <h3>{print('mailbox_position')}</h3>
                    <Row>
                        <span>{print('span_model')}</span>
                        <select>
                            <option value={null}>{print('choose_from_list')}</option>
                            <option>PB003</option>
                        </select>
                    </Row>
                    <Row>
                        <span>{print('span_width')} (mm)</span>
                        <input type='number' value={width} onChange={(e) => setWidth(e.target.value)} onBlur={(e) => {
                            const value = e.target.value
                            if(value < 300) return setWidth(300)
                            if(value > 2940) return setWidth(2940)
                            setWidth(round(value))
                        }} />
                        {parseInt(width) !== round(width) && width > 0 ? <div className='info-text'>{print('value_rounded')} {round(width)}mm</div> : <></>}
                    </Row>
                    <Row>
                        <span>{print('span_height')} (mm)</span>
                        <input type='number' value={height} onChange={(e) => setHeight(e.target.value)} onBlur={(e) => {
                            const value = e.target.value
                            if(value < 500) return setHeight(500)
                            if(value > 1440 && width > 1440) return setHeight(1440)
                            if(value > 2000 && width <= 1440) return setHeight(2000)
                            setHeight(round(value))
                        }} />
                        {width ? width > 1440 ? <span>Max: <strong>1440mm</strong></span> : <span>Max: <strong>2000mm</strong></span> : ''}
                        {parseInt(height) !== round(height) && height > 0 ? <div className='info-text'>{print('value_rounded')} {round(height)}mm</div> : <></>}
                    </Row>
                    <Row>
                        <span>{print('important_notice')}</span>
                    </Row>
                    <Row>
                        {
                            (height === null || width === null) ? 
                            <button className="inactive">{print('start_design')}</button> : 
                            <Link to={`/mailbox/design/${width}/${height}`}>
                                <button>{print('start_design')}</button>
                            </Link>
                        }
                    </Row>
                </Col>
            </Inner>
            <Background/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`
const Inner = styled.div`
    display: flex;
    width: 60%;
    padding: 3%;
`
const Background = styled.div`
    width: 40%;
    background-color: #cccccc;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: url(${bg});
    background-size: cover;
`
const Col = styled.div`
    width: 50%;
    padding: 0 5%;
`
const Row = styled.div`
    width: 100%;    
    font-size: 12px;
    margin-bottom: 30px;
    position: relative;
    .info-text {
        width: 300px;
        height: 30px;
        background-color: #38A78B;
        line-height: 30px;
        position: absolute;
        right: -320px;
        top: 30px;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        text-align: center;
        border-radius: 2rem;
        padding: 0 10px;
    }
    span {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
    }
    input {
        background-color: #969899;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        margin-right: 10px;
        padding: 0 5px;
    }
    select {
        background-color: #969899;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        padding-left: 5px;
    }
    button {
        background-color: #38A78B;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        cursor: pointer;
        &.inactive {
            color: #969899;
            background-color: #ddd;
        }
    }
`
export default Mailbox;