import { useState } from 'react'
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import bg from '../assets/bg.png';
import { types } from './shared/types.js'
import { useLanguage } from './shared/hooks';

const Hole = () => {
    const [model, setModel] = useState(null)
    const [width, setWidth] = useState(null)
    const [height, setHeight] = useState(null)
    const [tab, setTab] = useState('standard')
    const { print } = useLanguage()

    const round = (n) => Math.round(n/10)*10

    const resolveModelOptions = () => {
        const sizes = types.find(t => t.name === model)?.sizes
        if(sizes) {
            return sizes.map(s => <option key={s} value={s}>{s}</option>)
        }
    }
    
    return (
        <Wrapper>
            <Inner>
                <AppTabs>
                        <Tab onClick={() => setTab('standard')} className={tab === 'standard' ? 'active' : ''}>{print('cut_models')}</Tab>
                        <Tab onClick={() => setTab('custom')} className={tab === 'custom' ? 'active' : ''}>{print('cut_dimension')}</Tab>
                </AppTabs>
                <Cols>
                    {tab === 'standard' ? <Col>
                        <h3>{print('cut_models')}</h3>
                        <Row>
                            <span>{print('span_model')}</span>
                            <select onChange={(e) => setModel(e.target.value)}>
                                <option value={null}>{print('choose_from_list')}</option>
                                {types.map(t => <option key={t.name} value={t.name}>{t.name}</option>)}
                            </select>
                        </Row>
                        <Row>
                            <span>{print('span_height')} (mm)</span>
                            <select onChange={(e) => setHeight(e.target.value)}>
                                <option value={null}>{print('choose_from_list')}</option>
                                {resolveModelOptions()}
                            </select>
                        </Row>
                        <Row>
                            <span>{print('span_width')} (mm)</span>
                            <input type='number' onChange={(e) => setWidth(e.target.value)} onBlur={(e) => setWidth(round(width))} value={width} />
                            {parseInt(width) !== round(width) && width > 0 ? <div className='info-text'>{print('value_rounded')} {round(width)}mm</div> : <></>}
                        </Row>
                        <Row>
                            <span>{print('important_notice')}</span>
                        </Row>
                        <Row>
                        {
                            (height === null || width === null) || (isNaN(width) || isNaN(height)) || width < 1000 || height < 300 ? 
                            <button className="inactive">{print('start_design')}</button> : 
                            <Link to={`/hole/design/${width}/${height}`}>
                                <button>{print('start_design')}</button>
                            </Link>
                        }
                        </Row>
                    </Col> : <></>}
                    {tab === 'custom' ? <Col>
                        <h3>{print('cut_dimension')}</h3>
                        <Row>
                            <span>{print('span_height')} (mm)</span>
                            <Group>
                                <input type='number' value={height} onChange={(e) => setHeight(e.target.value)} onBlur={(e) => setHeight(round(e.target.value))} />
                                <button onClick={() => setHeight(height + 10)}>+</button>
                                <button onClick={() => setHeight(height - 10)}>-</button>
                                {parseInt(height) !== round(height) && height > 0 ? <div className='info-text'>{print('value_rounded')} {round(height)}mm</div> : <></>}
                            </Group>
                        </Row>
                        <Row>
                            <span>{print('span_width')} (mm)</span>
                            <Group>
                            <input type='number' value={width} onChange={(e) => setWidth(e.target.value)} onBlur={(e) => setWidth(round(e.target.value))} />
                                <button onClick={() => setWidth(width + 10)}>+</button>
                                <button onClick={() => setWidth(width - 10)}>-</button>
                                {parseInt(width) !== round(width) && width > 0 ? <div className='info-text'>{print('value_rounded')} {round(width)}mm</div> : <></>}
                            </Group>
                        </Row>
                        <Row>
                        {
                            (height === null || width === null) || (isNaN(width) || isNaN(height)) || width < 1000 || height < 300 ? 
                            <button className="inactive">{print('start_design')}</button> : 
                            <Link to={`/hole/design/${width}/${height}`}>
                                <button>{print('start_design')}</button>
                            </Link>
                        }
                        </Row>
                    </Col> : <></>}
                </Cols>
            </Inner>
            <Background/>
        </Wrapper>
    )
}
const AppTabs = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: left;
  a {
    text-decoration: none !important;
  }
`
const Tab = styled.div`
  width: 250px;
  height: 100%;
  background-color: #4C5152;
  border-radius: 15px;
  line-height: 40px;
  text-align: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
  cursor: pointer;
  &.active {
    background-color: rgb(56, 167, 139);
    color: #fff;
  }
  &.special {
    background-color: #4C5152;
  }
  &:hover {
    background-color: #E50019;
    &.active {
      background-color: rgb(56, 167, 139);
    }
  }
`
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`
const Inner = styled.div`
    display: flex;
    width: 60%;
    padding: 3%;
    flex-direction: column;
`
const Background = styled.div`
    width: 40%;
    background-color: #cccccc;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: url(${bg});
    background-size: auto 100%;
    background-position-x: 50%;
`
const Cols = styled.div`
    background-color: #eee;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`
const Col = styled.div`
    width: 50%;
    padding: 0 5%;
`
const Row = styled.div`
    width: 100%;    
    font-size: 12px;
    margin-bottom: 30px;
    position: relative;
    .info-text {
        width: 300px;
        height: 30px;
        background-color: #38A78B;
        line-height: 30px;
        position: absolute;
        right: -320px;
        top: 30px;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        text-align: center;
        border-radius: 2rem;
        padding: 0 10px;
    }
    span {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
    }
    input {
        background-color: #969899;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        margin-right: 10px;
        padding: 0 5px;
    }
    select {
        background-color: #969899;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        padding-left: 5px;
    }
    button {
        background-color: #38A78B;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        cursor: pointer;
        &.inactive {
            color: #969899;
            background-color: #ddd;
        }
    }
`
const Group = styled.div`
    display: flex;
    button {
        width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 80px;
        background-color: #ccc !important;
        margin-left: 3px;
        font-size: 18px;
        &:hover {
            background-color: #333 !important;
            cursor: pointer;
        }
    }
`
export default Hole;