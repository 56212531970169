import styled from "@emotion/styled";

const colors = ['red', 'green', 'blue', 'purple', 'yellow']

export const Wrapper = styled.div`
    padding: 2% 1%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
`
export const Header = styled.div`
    margin-top: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const Inner = styled.div`
    width: 100%;
    height: ${({height}) => `calc(${height}px + 120px)`};
    background-color: #eee;
    clear: both;
    display: flex;
    overflow: hidden;
    padding: 60px 80px;
`
export const Pole = styled.div`
    width: 20px;
    height: ${({height}) => `${height}px`};
    background: #333;
    position: relative;
    &:after, &:before {
        content: '';
        width: 10px;
        height: 6px;
        background: #333;
        position: absolute;
    }
    &:before {
        top: 20px;
    }
    &:after {
        bottom: 20px;
    }
    &.left {
        &:after, &:before {
            left: 20px;
        }
    }
    &.right {
        &:after, &:before {
            right: 20px;
        }
    }
`
export const Workspace = styled.div`
    width: 100%;
    margin: 0 0px;
    position: relative;
    .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #ccc;
        box-sizing: border-box;
    }
    .logo{
        width: 200px;
        height: 60px;
        position: absolute;
        box-sizing: border-box;
        top: -80px;
        right: 0;
    }
    .resize-handle {
        width: 10px;
        height: 10px;
        background-color: #E50019;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .child-container {
        position: relative;
        color: #fff;
    }
    .size-auto {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
    }
`
export const Hole = styled.div`
    background-color: #222;
    position: absolute;
    z-index: 2;
    opacity: .1;
    &.active {
        opacity: 1;
    }
    .number {
        position: absolute;
        top: 0;
        right: 4px;
        text-align: center;
        font-size: 30px;
    }
    .inputs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        >div {
            display: flex;
            font-size: 11px;
            margin: 3px 0;
            clear: both;
            flex-direction: column;
            text-align: center;
            span {
                font-weight: 600;
                font-size: 14px;
            }
            input {
                width: 70px;
                border: 0;
                border-radius: 5px;
                margin-left: 0px;
                text-align: center;
                padding: 3px 5px;
                font-size: 14px;
                font-weight: 600;
                background: #fff;
            }
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button {  

            opacity: 1;

            }
        }
    }
    .text-size {
        font-size: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        > span {
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
        }
    }
    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 20px;
        width: 100%;
    }
    .entry {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        height: 10px;
        background-color: #eee;
    }
`
export const Arrow = styled.div`
    position: absolute;
    height: 4px;
    background-color: ${({index}) => colors[index]};
    z-index: 0;
    cursor: pointer;
    &:hover {
        opacity: .6;
        .info .button-arrow {
            opacity: 1;
        }
    }
    > svg {
        color: ${({index}) => colors[index]};
    }
    .info {
        width: 100px;
        height: 40px;
        position: absolute;
        text-align: center;
        color: #fff;
        background-color: ${({index}) => colors[index]};
        line-height: 40px;
        border-radius: 15px;
        svg {
            width: 30px;
            height: 100%;
            float: right;
            background-color: #444;
            border-radius: 15px;
        }
        .button-arrow{
            color: #fff;
            opacity: 0.4;
            font-size: 12px;
        }
    }
    &.left {
        top: 50%;
        width: ${({size}) => `calc(${size}px)`};
        left: ${({size}) => `calc(-${size}px)`};
        >svg {
            left: ${({size}) => `calc(${size}px - 10px)`};
            position: absolute;
            top: -6px;
        }
        .info {
            right: 20px;
            top: -20px;
        }
    }
    &.right {
        top: 50%;
        width: ${({size}) => `calc(${size}px)`};
        right: ${({size}) => `calc(-${size}px)`};
        >svg {
            right: ${({size}) => `calc(${size}px - 10px)`};
            position: absolute;
            top: -6px;
        }
        .info {
            top: -20px;
            left: 20px;
        }
    }
    &.top {
        top: ${({size}) => `calc(-${size}px)`};
        left: 50%;
        width: 4px;
        height: ${({size}) => `calc(${size}px)`};
        >svg {
            top: ${({size}) => `calc(${size}px - 10px)`};
            position: absolute;
            left: -6px;
        }
        .info {
            bottom: 20px;
            left: -50px;
        }
    }
    &.bottom {
        bottom: ${({size}) => `calc(-${size}px)`};
        left: 50%;
        width: 4px;
        height: ${({size}) => `calc(${size}px)`};
        >svg {
            bottom: ${({size}) => `calc(${size}px - 10px)`};
            position: absolute;
            left: -6px;
        }
        .info {
            left: -50px;
            top: 20px;
        }
    }

`
export const Col = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: center;
`
export const Row = styled.div`   
    font-size: 12px;
    &.btn-end {
        position: absolute;
        top: -60px;
        right: 0;
    }
    > span {
        width: 100%;
        display: flex;
        font-size: 14px;
        line-height: 16px;
        padding: 0 0px;
        margin-left: 10px;
        margin-right: 10px;
        white-space: nowrap;
    }
    input {
        background-color: #969899;
        border: 0;
        border-radius: 20px;
        height: 40px;
        color: #fff;
        width: 100%;
        margin-right: 10px;
        padding: 0 5px;
    }
    select {
        background-color: #969899;
        border: 0;
        border-radius: 20px;
        height: 40px;
        color: #fff;
        width: 100%;
        padding-left: 5px;
    }
    button {
        background-color: #E50019;
        border: 0;
        border-radius: 20px;
        height: 40px;
        color: #fff;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        &:hover {
            opacity: .5;
        }
        &.inactive {
            color: #969899;
            background-color: #ddd;
        }
        svg {
            font-size: 20px;
            margin-right: 10px;
        }
        span {
            width: auto;
            margin-bottom: 0;
        }
    }
`

export const Summary = styled.div`
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding: 10px;
        >span {
            text-align: center;
            strong {
                display: block;
                width: 100%;
            }
        }
    }
`

export const Modal = styled.div`
    display: block;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    .modal-inner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 850px;
        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 0.3rem;
            outline: 0;
            min-width: 600px;
            min-height: 400px;
            padding: 20px;
            input {
                width: 100%;
                line-height: 4rem;
                font-size: 16px;
                font-weight: 600;
                border-radius: 2rem;
                padding: 0px 2rem;
                border: none;
                background: rgb(229, 229, 229);
                color: #000;
            }
            button {
                cursor: pointer;
                line-height: 4rem;
                font-size: 16px;
                padding: 0px 3rem;
                margin: 0px;
                background: rgb(45, 50, 52);
                border-radius: 2rem;
                color: rgb(255, 255, 255);
                border: none;
                &.preview {
                    background: rgb(1, 101, 199);
                    margin-bottom: 1rem;
                }
                &.send {
                    background: rgb(68, 175, 105);
                }
            }
        }
    }
`