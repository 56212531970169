import { useRef, useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { usePrompt } from './shared/hooks'
// import axios from 'axios'
// import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DragResizeContainer from '../lib/drag-resize';
import { AiFillCaretLeft, AiFillCaretRight, AiFillCaretUp, AiFillCaretDown, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

import { Wrapper, Header, Inner, Workspace, Arrow, Hole, Col, Row, Summary, Modal } from './shared/styled'

import { useLanguage } from './shared/hooks';

import logo from '../konsport-logo.png'

const MailboxDesign = () => {
    const workspace = useRef()
    const {width, height} = useParams()
    const [holes, setHoles] = useState([])
    const [workspaceHeight, setWorkspaceHeight] = useState(480)
    const [blocker, setBlocker] = useState(false)
    const { print } = useLanguage()
    const [showModal, setShowModal] = useState(false)
    usePrompt("Twój projekt jest niezapisany, na pewno chcesz kontynuować?", blocker);
    // const [email, setEmail] = useState('')
    // const [name, setName] = useState('')
    // const [comment, setComment] = useState('')

    useEffect(() => {
        const element = workspace.current
        if(element) {
            const workspaceWidth = element.clientWidth
            const workspaceHeight = element.clientHeight

            const ratioWidth = width / workspaceWidth
            const ratioHeight = height / workspaceHeight

            setHoles([{key: Math.floor(Math.random()*999999), width: 300/ratioWidth, height: 500/ratioHeight, x: 0, y: 0, zIndex: holes.length+1}])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace?.current?.clientWidth, workspace?.current?.clientHeight])
    const grid = 1
    useEffect(() => {
        const workspaceRatio = height/width
        setWorkspaceHeight(workspace.current.clientWidth * workspaceRatio)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace?.current?.clientWidth, workspace?.current?.clientHeight])
    const round = (n) => {
        const result = Math.round(n/grid)*grid
        if(result < 0) {
            return 0
        }
        return result
    }
    const onLayoutChange = (e) => {
        const rounded = e.map(item => roundItem(item))
        setHoles(rounded)
    }
    const roundItem = (item) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight
        const ratioWidth = width / (workspaceWidth)
        const ratioHeight = height / workspaceHeight
        return {
            ...item, 
            x: round(calculateMM(item, 'left')) / ratioWidth, 
            y: round(calculateMM(item, 'top')) / ratioHeight,
            width: round(calculateMM(item, 'width')) / ratioWidth,
            height: round(calculateMM(item, 'height')) / ratioHeight,
        }
    }
    const generatePdf = async () => {
        setShowModal(false)
        setBlocker(false)
        html2canvas(document.querySelector("#capture"), {scrollY: -window.scrollY,
            removeContainer: false, onclone: function (documentCloned) {
                const holesItem = documentCloned.querySelectorAll('.child-container');
                for (const hole of holesItem) {
                    hole.classList.add('active');
                }
            }}).then(canvas => {
                const canvasWidth = canvas.width
                const canvasHeight = canvas.height
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: canvasWidth > canvasHeight ? "landscape" : "portrait",
                });
                var w = pdf.internal.pageSize.getWidth();
                var h = pdf.internal.pageSize.getHeight();

                pdf.addImage(imgData, 'PNG', 0, 0, Math.min(w, canvasWidth), Math.min(h, canvasHeight));
                pdf.save("download.pdf"); 
        });
    }
    const calculateDistance = (item, area) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight

        switch(area) {
            case 'left': {
                return Math.round(item.x)
            }
            case 'right': {
                return Math.round(workspaceWidth - (item.x + (item.width)))
            }
            case 'top': {
                return Math.round(item.y)
            }
            case 'bottom': {
                return Math.round(workspaceHeight - (item.y + item.height))
            }
            case 'width': {
                return Math.round(item.width)
            }
            case 'height': {
                return Math.round(item.height)
            }
            default:
                return 0
        }
    }
    // const sendPdf = async () => {
    //     return await axios.post('https://www.kreator.konsport.com/send.php', {})
    // }
    // const sendProject = async () => {
    //     //ReactPDF.render(<PDFDocument />, `${__dirname}/example.pdf`);
        
    //     const id = uuidv4()
    //     const payload = {
    //         id,
    //         email: 'shatens@gmail.com',
    //         name: 'Project',
    //         pdfUrl: 'https://google.com',
    //         project: {
    //             width,
    //             height,
    //             holes
    //         }
    //     }
    //     const data = await axios.post('https://www.api.my.konsport.com/wyciecia/projects', payload, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     console.log('response', data)
    // }
    const calculateMM = (item, area) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight

        const ratioWidth = width / workspaceWidth
        const ratioHeight = height / workspaceHeight

        switch(area) {
            case 'left': {
                return Math.round(item.x * ratioWidth)
            }
            case 'right': {
                return Math.round(width - (item.x + item.width) * ratioWidth)
            }
            case 'top': {
                return Math.round(item.y * ratioHeight)
            }
            case 'bottom': {
                return Math.round(height - (item.y + item.height) * ratioHeight)
            }
            case 'width': {
                return Math.round(item.width * ratioWidth)
            }
            case 'height': {
                return Math.round(item.height * ratioHeight)
            }
            default:
                return 0
        }
    }
    const calculateSize = (value, area) => {
        const element = workspace.current
        if(!element) return 0
        const workspaceWidth = element.clientWidth
        const workspaceHeight = element.clientHeight

        const ratioWidth = width / workspaceWidth
        const ratioHeight = height / workspaceHeight

        console.log('Ratios', ratioWidth, ratioHeight)
        switch(area) {
            case 'x': {
                return value / ratioWidth
            }
            case 'y': {
                return value / ratioHeight
            }
            case 'width': {
                return value / ratioWidth
            }
            case 'height': {
                return value / ratioHeight
            }
            default:
                return 0
        }
    }
    return (
        <Wrapper>
            {showModal ? <Modal  data-html2canvas-ignore>
                <div className="modal-inner">
                    <div className="content">
                        <AiOutlineClose style={{position: 'absolute', top: 10, right: 10, fontSize: 20, cursor: 'pointer'}} onClick={() => setShowModal(false)} />
                        <Row style={{fontSize: '20px', textAlign: 'center'}}>
                            {print('finish_project')}
                        </Row>
                        <Row style={{flexDirection: 'row', display: 'flex', marginTop: 40}}>
                            <input type="email" placeholder='Adres email'></input>
                            <input type="text" placeholder='Nazwa projektu'></input>
                            <input type="text" placeholder='Komentarz'></input>
                        </Row>
                        <Row style={{width: 300, alignSelf: 'center', marginTop: 60}}>
                            <button className="preview" onClick={() => {
                                generatePdf()
                            }}>
                                <span>{print('download_preview')}</span>
                            </button>
                            {/* <button className="send">
                                <span>Wyślij projekt</span>
                            </button> */}
                        </Row>
                    </div>
                </div>
            </Modal> : <></>}
            <Row style={{alignSelf: 'flex-start'}} className="btn-end" data-html2canvas-ignore>
                        {holes.length > 0 ?<button style={{backgroundColor: '#38A78B'}} onClick={() => {
                            generatePdf()
                        }}><AiOutlineCheck /> <span>{print('finish_design')}</span></button> : <></>}
            </Row>
            <Header>
                <Col>
                    <Row>
                        <span>{print('your_span_width')}: &nbsp; <strong>{width} mm</strong></span>
                    </Row>
                    <Row>
                        <span>{print('your_span_height')}: &nbsp; <strong>{height} mm</strong></span>
                    </Row>
                </Col>
                <Col data-html2canvas-ignore>
                    <Row style={{alignSelf: 'flex-end'}}>
                        <button onClick={() => document.location.href = '/'} style={{backgroundColor: '#444'}}><span>{print('start_fresh')}</span></button>
                    </Row>
                </Col>                
            </Header>
            <Inner height={workspaceHeight}>
                <Workspace ref={workspace}>
                    <div className="bg"></div>
                    <DragResizeContainer 
                        className='resize-container'
                        resizeProps={{ 
                            minWidth: 150, 
                            minHeight: 150, 
                            enable: false,
                            handle: {}
                        }}
                        layout={holes}
                        dragProps={{ disabled: false }}
                        onLayoutChange={onLayoutChange}
                    >
                        {holes.map((single, i) => {
                            return (
                                <Hole key={single.key} className='child-container size-auto border active' onClick={() => setBlocker(true)}>
                                    <div className="entry"></div>
                                    <div className="text-size">
                                        <span>{print('mailbox_size')}:</span>
                                        <span>{print('short_height')}: 500mm</span>
                                        <span>{print('short_width')}: 300mm</span>
                                    </div>
                                    <div className="arrows">
                                        <Arrow key={'left-arrow'} className="left arrow" index={i} size={calculateDistance(single, 'left')} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.x = calculateSize((calculateMM(single, 'left') - grid), 'x')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretRight  data-html2canvas-ignore />
                                            <div className="info">
                                                <AiFillCaretLeft className="button-arrow" style={{float: 'left'}} data-html2canvas-ignore />
                                                <strong>{calculateMM(single, 'left')}</strong>mm
                                            </div>
                                        </Arrow>
                                        <Arrow key={'right-arrow'} className="right arrow" size={calculateDistance(single, 'right')} index={i} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.x = calculateSize((calculateMM(single, 'left') + grid), 'x')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretLeft  data-html2canvas-ignore />
                                            <div className="info">
                                                <strong>{calculateMM(single, 'right')}</strong>mm
                                                <AiFillCaretRight className="button-arrow" data-html2canvas-ignore />
                                            </div>
                                        </Arrow>
                                        <Arrow key={'top-arrow'} className="top arrow" size={calculateDistance(single, 'top')} index={i} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.y = calculateSize((calculateMM(single, 'top') - grid), 'y')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretDown  data-html2canvas-ignore />
                                            <div className="info">
                                                <strong>{calculateMM(single, 'top')}</strong>mm
                                                <AiFillCaretUp className="button-arrow" data-html2canvas-ignore />
                                            </div>
                                        </Arrow>
                                        <Arrow key={'bottom-arrow'} className="bottom arrow" size={calculateDistance(single, 'bottom')} index={i} onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setHoles(holes.map(h => {
                                                if(h.key === single.key) {
                                                    h.y = calculateSize((calculateMM(single, 'top') + grid), 'y')
                                                }
                                                return h
                                            }))
                                        }}>
                                            <AiFillCaretUp  data-html2canvas-ignore />
                                            <div className="info">
                                                <strong>{calculateMM(single, 'bottom')}</strong>mm
                                                <AiFillCaretDown className="button-arrow" data-html2canvas-ignore />
                                            </div>
                                        </Arrow>
                                    </div>
                                </Hole>
                            );
                        })}
                    </DragResizeContainer>
                </Workspace>
            </Inner>
            <div style={{width: '100%', height: 60, position: 'relative'}}>
                <img className="logo" style={{clear: 'both', position: 'absolute', right: 0}} width="200" src={logo} alt="logo" />
            </div>
            <Summary>
                <h2>{print('project_mailbox')}</h2>
                {holes.map((hole, i) => {
                    return (
                        <div className="item">
                            <span>{print('width')}: <strong>{calculateMM(hole, 'width')}mm</strong></span>
                            <span>{print('height')}: <strong>{calculateMM(hole, 'height')}mm</strong></span>
                            <span>{print('distance')} {print('left')}: <strong>{calculateMM(hole, 'left')}mm</strong></span>
                            <span>{print('distance')} {print('right')}: <strong>{calculateMM(hole, 'right')}mm</strong></span>
                            <span>{print('distance')}  {print('top')}: <strong>{calculateMM(hole, 'top')}mm</strong></span>
                            <span>{print('distance')}  {print('bottom')}: <strong>{calculateMM(hole, 'bottom')}mm</strong></span>
                        </div>
                    )
                })}
            </Summary>
        </Wrapper>
    )
}

export default MailboxDesign