import { useState } from 'react'
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import bg from '../assets/bg.png';

const HoleExtra = () => {
    const [width, setWidth] = useState(null)
    const [height, setHeight] = useState(null)

    const round = (n) => Math.round(n/10)*10

    return (
        <Wrapper>
            <Inner>
                <Col>
                    <h3>Wyciecia dla wymiaru niestandardowego</h3>
                    <Row>
                        <span>Wysokość przęsła (mm)</span>
                        <Group>
                            <input type='number' value={height} onChange={(e) => setHeight(e.target.value)} onBlur={(e) => setHeight(round(e.target.value))} />
                            <button onClick={() => setHeight(height + 10)}>+</button>
                            <button onClick={() => setHeight(height - 10)}>-</button>
                        </Group>
                    </Row>
                    <Row>
                        <span>Szerokość przęsła (mm)</span>
                        <Group>
                        <input type='number' value={width} onChange={(e) => setWidth(e.target.value)} onBlur={(e) => setWidth(round(e.target.value))} />
                            <button onClick={() => setWidth(width + 10)}>+</button>
                            <button onClick={() => setWidth(width - 10)}>-</button>
                        </Group>
                    </Row>
                    <Row>
                    {
                        (height === null || width === null) || (isNaN(width) || isNaN(height)) || width < 1000 || height < 300 ? 
                        <button className="inactive">Rozpocznij projektowanie</button> : 
                        <Link to={`/hole/design/${width}/${height}`}>
                            <button>Rozpocznij projektowanie</button>
                        </Link>
                    }
                    </Row>
                </Col>
            </Inner>
            <Background/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`
const Inner = styled.div`
    display: flex;
    width: 60%;
    padding: 3%;
`
const Background = styled.div`
    width: 40%;
    background-color: #cccccc;
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: url(${bg});
    background-size: cover;
`
const Col = styled.div`
    width: 50%;
    padding: 0 5%;
`
const Row = styled.div`
    width: 100%;    
    font-size: 12px;
    margin-bottom: 30px;
    span {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
    }
    input {
        background-color: #969899;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        margin-right: 10px;
        padding: 0 5px;
    }
    select {
        background-color: #969899;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        padding-left: 5px;
    }
    button {
        background-color: #38A78B;
        border: 0;
        border-radius: 10px;
        height: 40px;
        color: #fff;
        width: 100%;
        cursor: pointer;
        &.inactive {
            color: #969899;
            background-color: #ddd;
        }
    }
`
const Group = styled.div`
    display: flex;
    button {
        width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 80px;
        background-color: #ccc !important;
        margin-left: 3px;
        font-size: 18px;
        &:hover {
            background-color: #333 !important;
            cursor: pointer;
        }
    }
`
export default HoleExtra;