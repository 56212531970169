import { createContext, useState } from 'react'
import styled from '@emotion/styled';
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from './pages/shared/hooks';
import './App.css';

export const LanguageContext = createContext('pl');

const App = ({children}) => {
  const [currentLanguage, setCurrentLanguage] = useState('pl')
  const location = useLocation();
  const { print } = useLanguage()
  
  return (
    <LanguageContext.Provider value={currentLanguage}>
      <AppBody className="app">
        <header>
          <h1><img src="https://konsport.com.pl/pliki/konsport-biale-logo.png" alt="logo" style={{height: '50px'}} /></h1>
          <div className="language">
            {currentLanguage === 'pl' ? 
            <span onClick={() => setCurrentLanguage('en')}>English version</span> :
            <span onClick={() => setCurrentLanguage('pl')}>Wersja polska</span>}
          </div>
        </header>
        <div className='content'>
          <AppWrapper>
            <AppTabs>
              <Link to='/hole'><Tab className={location.pathname.includes('hole') || location.pathname === '/' ? 'active': ''}>{print('technical_hole', currentLanguage)}</Tab></Link>
              <Link to='/mailbox'><Tab className={location.pathname.includes('mailbox') ? 'active': ''}>{print('mailbox_span', currentLanguage)}</Tab></Link>
              <a href={'https://www.wyciecia.konsport.com/'} target="_blank" rel="noreferrer"><Tab className='special'>{print('marki_creator', currentLanguage)}</Tab></a>
            </AppTabs>
            <AppInner id="capture">
              {children}
            </AppInner>
          </AppWrapper>
        </div>
        <Footer>
          <span>© Konsport</span>
          <span>Jana Kilinskiego 75</span>
          <span>95-083 Kazimierz</span>
          <span>+48 43 677 50 63</span>
          <span>biuro@konsport.com.pl</span>
        </Footer>
      </AppBody>
    </LanguageContext.Provider>
  );
}

const AppBody = styled.div`
  header {
    position: relative;
  }
  .language {
    position: absolute;
    right: 0;
    span {
      cursor: pointer;
    }
  }
`

const AppWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const AppTabs = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: left;
  a {
    text-decoration: none !important;
  }
`
const Tab = styled.div`
  width: 250px;
  height: 100%;
  background-color: #4C5152;
  border-radius: 15px;
  line-height: 60px;
  text-align: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
  &.active {
    background-color: #fff;
    color: #4C5152;
  }
  &.special {
    background-color: #4C5152;
  }
  &:hover {
    background-color: #E50019;
    &.active {
      background-color: #fff;
    }
  }
`

const AppInner = styled.div`
  background-color: #fff;
  border-radius: 30px;
  border-top-left-radius: 0;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  span {
    color: #999;
    width: 200px;
    &:first-child {
      margin-right: 400px;
    }
  }
`
export default App;
